import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import GeoHarvestLogo from "../Assets/Logo/geo-harvest-logo.png";
import DownloadIcon from "@mui/icons-material/Download";
import CircularProgress from "@mui/material/CircularProgress";
import Swal from "sweetalert2";
import { ThemeProvider, createTheme, useTheme } from "@mui/material/styles";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { Link, useNavigate } from "react-router-dom";
import {
  Drawer,
  Box,
  List,
  ListItem,
  ListItemIcon,
  Divider,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";

// import {
//   GoogleMap,
//   useLoadScript,
//   Polygon,
//   GroundOverlay,
// } from "@react-google-maps/api";
import { generatePdfDocument } from "../Create-PDF/PDF"; // Import the PDF generation function
import CircularLoader from "../All-Loader/mapLoader"; // Import the CircularLoader component
import LegendNdvi from "../Legend-Gradient/legentNdvi";
import LegendNdmi from "../Legend-Gradient/legentNdmi";
import LegendRgb from "../Legend-Gradient/legentRgb";
import LegendReci from "../Legend-Gradient/legentReci";
import { SnackbarProvider, useSnackbar } from "notistack";
import SearchLocation from "../Search-Location/searchLocation";
import WeatherWidgets from "../weather-forecast/custom-weather-widget-dashboard";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../Language-Switcher/languageSwitcher";
import html2canvas from "html2canvas";
import Stack from "@mui/material/Stack";
import RefreshIcon from "@mui/icons-material/Refresh";
import LogOut from "../LogOut/logOut";
import LogOutDropDown from "../LogOut/logOutDropDown";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeletePolygoneDropDown from "../MaterialUi-Components/deletePolygoneDropDown";
import L from "leaflet";
import "./sentinel2Harmonized.css";
import CustomZoomControl from "../Leaflet-Map-Components/customZoomControl";
import SearchComponent from "../Leaflet-Map-Components/SearchComponent";
import CloseIcon from "@mui/icons-material/Close";
import { MoreVert } from "@mui/icons-material"; // Import the MUI icon
import DrawIcon from "@mui/icons-material/Draw";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import CloudIcon from "@mui/icons-material/Cloud";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import GetAppIcon from "@mui/icons-material/GetApp";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import {
  MapContainer,
  TileLayer,
  FeatureGroup,
  Polygon,
  useMap,
  ImageOverlay,
  useMapEvent,
} from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import CurrentLocation from "../Leaflet-Map-Components/currentLocation";
import usePolygonStore from "../../zustand/polygoneStore";
import PolygoneDrawer from "../MaterialUi-Components/polygoneDrawer";
import { Modal, Button } from "@mui/material"; // or any other modal component you are using
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Feedback from "../Feedback/feedback";
import {
  ListItemText,
  Tooltip,
  Collapse,
  Switch,
  Typography,
  styled,
} from "@mui/material";
import {
  ExpandLess,
  ExpandMore,
  ArrowBack as ArrowBackIcon,
  Menu as MenuIcon,
  Home as HomeIcon,
  Info as InfoIcon,
  ContactMail as ContactMailIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ShapeLineIcon from "@mui/icons-material/ShapeLine";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import KisanLogo from "../Assets/Logo/kisan360-logo.png";
import useStore from "../../zustand/useStore";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "black",
    color: "white",
    fontSize: 12,
  },
});

const darkModeTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#212930",
    },
    text: {
      primary: "#9fb4c7",
    },
  },
});

const libraries = ["drawing", "places"];
const EarthEngineData = () => {
  const { setAoi: setAoiInZustand } = useStore(); // Destructure setAoi at the component level
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [cropType, setCropType] = useState("");
  const [polygonName, setPolygonName] = useState("");
  const [availableDates, setAvailableDates] = useState([]);
  const [selectedPolygon, setSelectedPolygon] = useState(null);
  const [selectedPolygonId, setSelectedPolygonId] = useState(null);
  const [imageCount, setImageCount] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");
  const [viewType, setViewType] = useState("");
  const [ndviThumbUrls, setNdviThumbUrls] = useState([]);
  const [ndmiThumbUrls, setNdmiThumbUrls] = useState([]);
  const [reciThumbUrls, setReciThumbUrls] = useState([]);
  const [loading, setLoading] = useState(false); // Full-screen loading state
  const [fetching, setFetching] = useState(false); // Fetch button loading state
  const [error, setError] = useState(null);
  const [aoi, setAoi] = useState([]); // State to store the AOI coordinates
  const [isDownloading, setIsDownloading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [showWeatherWidget, setShowWeatherWidget] = useState(false);
  const [selectedCoords, setSelectedCoords] = useState(null);
  const [ndviGradientThumbUrls, setNdviGradientThumbUrls] = useState([]);
  const [ndmiGradientThumbUrls, setNdmiGradientThumbUrls] = useState([]);
  const [rgbThumbUrls, setRgbThumbUrls] = useState([]);
  const [ndviAreas, setNdviAreas] = useState([]);
  const [ndmiAreas, setNdmiAreas] = useState([]);
  const [reciAreas, setReciAreas] = useState([]);
  const [screenshotUrl, setScreenshotUrl] = useState("");
  const [loadingGenerateReport, setLoadingGenerateReport] = useState(false);
  const [loadingFetch, setLoadingFetch] = useState(false); // Separate loading state for fetching
  const [response, setResponse] = useState(null);
  const [polygons, setPolygons] = useState([]);
  const [mapInstance, setMapInstance] = useState(null);
  const [currentPolygon, setCurrentPolygon] = useState(null);
  const mapRef = useRef(null); // Create a ref to access the map instance
  const [borderOpacity, setBorderOpacity] = useState(1.0);
  const { t, i18n } = useTranslation();
  const isRtl = i18n.dir() === "rtl";
  const currentLanguage = i18n.language;
  const [originalCenter, setOriginalCenter] = useState({
    lat: 31.3656,
    lng: 72.9942,
  });
  const isUrdu = i18n.language === "ur"; // Assuming 'ur' is the language code for Urdu
  const navigate = useNavigate();
  // Step 1: Create a state to manage the button text
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);

  const [customCropType, setCustomCropType] = useState("");
  const [buttonText, setButtonText] = useState(t("Select Map Type"));
  useEffect(() => {
    const storedViewType = localStorage.getItem("viewType");

    if (storedViewType) {
      setViewType(storedViewType);

      if (storedViewType === "ndvi") {
        setButtonText(t("Crop Health"));
      } else if (storedViewType === "ndmi") {
        setButtonText(t("Crop Water Content"));
      } else if (storedViewType === "rgb") {
        setButtonText(t("Satellite Image"));
      } else if (storedViewType === "reci") {
        setButtonText(t("Crop Nitrogen Content"));
      }
    }

    const updateButtonText = () => {
      if (window.innerWidth <= 380 && !storedViewType) {
        setButtonText(t("Select Map"));
      } else if (!storedViewType) {
        setButtonText(t("Select Map Type"));
      }
    };

    updateButtonText();
    window.addEventListener("resize", updateButtonText);

    return () => {
      window.removeEventListener("resize", updateButtonText);
    };
  }, [t]);

  const handleViewTypeSelect = (type) => {
    setViewType(type);
    localStorage.setItem("viewType", type); // Store the view type in localStorage
    setLoading(true);

    if (type === "ndvi") {
      setButtonText(t("Crop Health"));
    } else if (type === "ndmi") {
      setButtonText(t("Crop Water Content"));
    } else if (type === "rgb") {
      setButtonText(t("Satellite Image"));
    } else if (type === "reci") {
      setButtonText(t("Crop Nitrogen Content"));
    }

    setTimeout(() => setLoading(false), 2500);
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
    // Call your function to update the data based on the selected date
  };

  // start or end date restriction 6 month
  // Inside your component
  const handleStartDateChange = (date) => {
    setStartDate(date);
    if (date && endDate && date > endDate) {
      setEndDate(null); // Reset end date if start date is after the current end date
    }
  };

  const calculateMaxEndDate = (start) => {
    if (!start) return null;
    const maxEnd = new Date(start);
    maxEnd.setMonth(maxEnd.getMonth() + 2);
    return maxEnd;
  };

  const scrollNext = () => {
    const container = document.querySelector(".dates-container");
    if (container) {
      container.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  const scrollPrev = () => {
    const container = document.querySelector(".dates-container");
    if (container) {
      container.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  const handleCropTypeChange = (e) => {
    const value = e.target.value;
    setCropType(value);
    if (value !== "Other") {
      setCustomCropType(""); // Reset the custom crop type if "Other" is not selected
    }
  };

  // const [originalZoom, setOriginalZoom] = useState(13);
  // const { isLoaded, loadError } = useLoadScript({
  //   googleMapsApiKey: "AIzaSyC_4pCYuadBC7676P4zXOFZD9Oc1uDfo0M", // Replace with your Google Maps API key
  //   libraries,
  // });
  const setPolygonsDrawer = usePolygonStore((state) => state.setPolygons);

  const handleOpenModal = async () => {
    try {
      // Determine the base URL based on the environment
      const baseUrl =
        process.env.NODE_ENV === "development"
          ? "http://localhost:8080"
          : "https://api.kisan360.net"; // dynamically set protocol (http or https)
      const response = await fetch(`${baseUrl}/polygons/nextFieldName`);
      const data = await response.json();
      setPolygonName(data.nextFieldName);
    } catch (error) {
      console.error("Failed to fetch next field name:", error);
    }

    setIsModalOpen(true);
  };

  // Fetch earth engine images and anaylsis start here

  const fetchImageCountAndDates = async () => {
    // Function to generate a unique ID based on polygon data
    const generateUniqueId = (polygonData) => {
      // Simple hash function to create a unique ID from the polygon coordinates
      return `polygon_${btoa(JSON.stringify(polygonData))}`;
    };

    // Generate a unique ID for the polygon
    const uniqueId = generateUniqueId(aoi);

    setFetching(true); // Start fetching
    setError(null);

    try {
      if (!aoi || aoi.length === 0) {
        throw new Error("Invalid AOI coordinates.");
      }

      // Determine the base URL based on the environment
      const baseUrl =
        process.env.NODE_ENV === "development"
          ? "http://localhost:8080"
          : "https://api.kisan360.net";

      const response = await axios.post(`${baseUrl}/earth-engine`, {
        startDate: startDate.toISOString().split("T")[0],
        endDate: endDate.toISOString().split("T")[0],
        polygonPath: aoi, // Send AOI coordinates
      });

      const {
        imageCount,
        imageDates,
        ndviThumbUrls = [],
        ndviGradientThumbUrls = [],
        ndmiGradientThumbUrls = [],
        ndmiThumbUrls = [],
        trueColorThumbUrls = [],
        reciThumbUrls = [], // Added for RECI
        ndviAreas,
        ndmiAreas,
        reciAreas = [], // Added for RECI areas
      } = response.data;

      // Filter duplicate dates - keep only one image per date
      const uniqueImagesByDate = new Map();

      imageDates.forEach((date, index) => {
        if (!uniqueImagesByDate.has(date)) {
          uniqueImagesByDate.set(date, {
            ndviThumbUrl: ndviThumbUrls[index] || null,
            ndviGradientThumbUrl: ndviGradientThumbUrls[index] || null,
            ndmiThumbUrl: ndmiThumbUrls[index] || null,
            ndmiGradientThumbUrl: ndmiGradientThumbUrls[index] || null,
            trueColorThumbUrl: trueColorThumbUrls[index] || null,
            reciThumbUrl: reciThumbUrls[index] || null, // Added for RECI
          });
        }
      });

      // Convert Map back to Arrays
      const filteredDates = Array.from(uniqueImagesByDate.keys());
      const filteredNdviThumbUrls = filteredDates
        .map((date) => uniqueImagesByDate.get(date).ndviThumbUrl)
        .filter(Boolean);
      const filteredNdviGradientThumbUrls = filteredDates
        .map((date) => uniqueImagesByDate.get(date).ndviGradientThumbUrl)
        .filter(Boolean);
      const filteredNdmiThumbUrls = filteredDates
        .map((date) => uniqueImagesByDate.get(date).ndmiThumbUrl)
        .filter(Boolean);
      const filteredNdmiGradientThumbUrls = filteredDates
        .map((date) => uniqueImagesByDate.get(date).ndmiGradientThumbUrl)
        .filter(Boolean);
      const filteredTrueColorThumbUrls = filteredDates
        .map((date) => uniqueImagesByDate.get(date).trueColorThumbUrl)
        .filter(Boolean);
      const filteredReciThumbUrls = filteredDates
        .map((date) => uniqueImagesByDate.get(date).reciThumbUrl)
        .filter(Boolean); // Added for RECI

      // Save to state
      setImageCount(filteredDates.length);
      setAvailableDates(filteredDates);
      setNdviThumbUrls(filteredNdviThumbUrls);
      setNdviGradientThumbUrls(filteredNdviGradientThumbUrls);
      setNdmiGradientThumbUrls(filteredNdmiGradientThumbUrls);
      setNdmiThumbUrls(filteredNdmiThumbUrls);
      setRgbThumbUrls(filteredTrueColorThumbUrls);
      setReciThumbUrls(filteredReciThumbUrls); // Added for RECI
      setNdviAreas(ndviAreas);
      setNdmiAreas(ndmiAreas);
      setReciAreas(reciAreas); // Added for RECI areas

      // Combine all data into a single object
      const polygonData = {
        imageCount: filteredDates.length,
        imageDates: filteredDates,
        ndviThumbUrls: filteredNdviThumbUrls,
        ndviGradientThumbUrls: filteredNdviGradientThumbUrls,
        ndmiThumbUrls: filteredNdmiThumbUrls,
        ndmiGradientThumbUrls: filteredNdmiGradientThumbUrls,
        trueColorThumbUrls: filteredTrueColorThumbUrls,
        reciThumbUrls: filteredReciThumbUrls, // Added for RECI
        ndviAreas,
        ndmiAreas,
        reciAreas, // Added for RECI areas
      };

      // Save the combined object to local storage using the unique ID
      localStorage.setItem(uniqueId, JSON.stringify(polygonData));

      if (filteredDates.length > 0) {
        setSelectedDate(filteredDates[0]);
      }

      // Set initial view type based on availability
      if (filteredTrueColorThumbUrls.length > 0) {
        setViewType("rgb");
        setButtonText(t("Satellite Image"));
      } else if (filteredNdviThumbUrls.length > 0) {
        setViewType("ndvi");
        setButtonText(t("Crop Health"));
      } else if (filteredNdmiThumbUrls.length > 0) {
        setViewType("ndmi");
        setButtonText(t("Crop Water Content"));
      } else if (filteredReciThumbUrls.length > 0) {
        // Added for RECI
        setViewType("reci");
        setButtonText(t("Crop Nitrogen Content"));
      }

      if (filteredDates.length > 0) {
        enqueueSnackbar(`Available images: ${filteredDates.length}`, {
          variant: "success",
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      enqueueSnackbar("No image found, increase date range.", {
        variant: "error",
      });
      setError(error);
    } finally {
      setFetching(false); // End fetching
    }
  };

  // Fetch earth engine images and anaylsis end here

  //// Polygone modal define and draw polygone shape on drawer start
  const handlePolygonCreated = (e) => {
    const layer = e.layer;
    const coordinates = layer.getLatLngs()[0].map((coord) => ({
      lat: coord.lat,
      lng: coord.lng,
    }));

    const newPolygon = {
      _id: Date.now().toString(),
      name: `Field ${polygons.length + 1}`,
      coordinates: coordinates,
    };

    setPolygons([...polygons, newPolygon]);
    setSelectedPolygon(newPolygon);
    setIsModalOpen(true);
  };

  const renderPolygonThumbnail = (polygon) => {
    const minX = Math.min(...polygon.coordinates.map((coord) => coord.lng));
    const maxX = Math.max(...polygon.coordinates.map((coord) => coord.lng));
    const minY = Math.min(...polygon.coordinates.map((coord) => coord.lat));
    const maxY = Math.max(...polygon.coordinates.map((coord) => coord.lat));

    const points = polygon.coordinates
      .map((coord) => {
        const x = ((coord.lng - minX) / (maxX - minX)) * 100;
        const y = ((coord.lat - minY) / (maxY - minY)) * 100;
        return `${x},${100 - y}`;
      })
      .join(" ");

    return (
      <svg
        width="65"
        height="65"
        viewBox="0 0 100 100"
        style={{
          backgroundColor: "#212930",
          padding: "8px",
          borderRadius: "10px",
        }}
      >
        <polygon
          points={points}
          style={{
            fill: "none",
            stroke: selectedPolygonId === polygon._id ? "white" : "#9fb4c7", // Slightly lighter background for selected
            strokeWidth: selectedPolygonId === polygon._id ? 2 : 1, // Slightly lighter background for selected
          }}
        />
      </svg>
    );
  };

  //// Polygone modal define and draw polygone shape on drawer end

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsUpdateModalOpen(false);
  };

  const handleOpenUpdateModal = (polygon) => {
    // Close the drawer immediately when the delete action is triggered
    setOpen(false);
    setSelectedPolygon(polygon); // Set the selected polygon data
    setPolygonName(polygon.name); // Populate form fields with the existing data
    setCropType(polygon.cropType);
    setStartDate(new Date(polygon.sowingDate));
    setEndDate(new Date(polygon.harvestingDate));
    setIsUpdateModalOpen(true); // Open the modal
  };

  // useEffect(() => {
  //   if (viewType) {
  //     localStorage.setItem("viewType", viewType);
  //   }
  // }, [viewType]);

  /// Save Process Images link into Local Storage
  useEffect(() => {
    const storedImageCount = localStorage.getItem("imageCount");
    const storedImageDates = localStorage.getItem("imageDates");
    const storedNdviThumbUrls = localStorage.getItem("ndviThumbUrls");
    const storedNdviGradientThumbUrls = localStorage.getItem(
      "ndviGradientThumbUrls"
    );
    const storedNdmiGradientThumbUrls = localStorage.getItem(
      "ndmiGradientThumbUrls"
    );
    const storedNdmiThumbUrls = localStorage.getItem("ndmiThumbUrls");
    const storedTrueColorThumbUrls = localStorage.getItem("trueColorThumbUrls");
    const storedReciThumbUrls = localStorage.getItem("reciThumbUrls"); // Added for RECI

    const storedNdviAreas = localStorage.getItem("ndviAreas");
    const storedNdmiAreas = localStorage.getItem("ndmiAreas");
    const storedReciAreas = localStorage.getItem("reciAreas"); // Added for RECI areas

    const storedViewType = localStorage.getItem("viewType");

    if (storedImageCount) setImageCount(Number(storedImageCount));
    if (storedImageDates) setAvailableDates(JSON.parse(storedImageDates));
    if (storedNdviThumbUrls) setNdviThumbUrls(JSON.parse(storedNdviThumbUrls));
    if (storedNdviGradientThumbUrls)
      setNdviGradientThumbUrls(JSON.parse(storedNdviGradientThumbUrls));
    if (storedNdmiGradientThumbUrls)
      setNdmiGradientThumbUrls(JSON.parse(storedNdmiGradientThumbUrls));
    if (storedNdmiThumbUrls) setNdmiThumbUrls(JSON.parse(storedNdmiThumbUrls));
    if (storedTrueColorThumbUrls)
      setRgbThumbUrls(JSON.parse(storedTrueColorThumbUrls));
    if (storedReciThumbUrls) setReciThumbUrls(JSON.parse(storedReciThumbUrls)); // Added for RECI

    if (storedNdviAreas) setNdviAreas(JSON.parse(storedNdviAreas));
    if (storedNdmiAreas) setNdmiAreas(JSON.parse(storedNdmiAreas));
    if (storedReciAreas) setReciAreas(JSON.parse(storedReciAreas)); // Added for RECI areas

    // Set the initial view type from local storage if available
    if (storedViewType) {
      setViewType(storedViewType);
    } else if (
      storedNdviThumbUrls &&
      JSON.parse(storedNdviThumbUrls).length > 0
    ) {
      setViewType("ndvi");
    } else if (
      storedNdmiThumbUrls &&
      JSON.parse(storedNdmiThumbUrls).length > 0
    ) {
      setViewType("ndmi");
    } else if (
      storedReciThumbUrls &&
      JSON.parse(storedReciThumbUrls).length > 0
    ) {
      // Added for RECI
      setViewType("reci");
    } else if (
      storedTrueColorThumbUrls &&
      JSON.parse(storedTrueColorThumbUrls).length > 0
    ) {
      setViewType("rgb");
    }

    // Set the initial selected date if available
    if (storedImageDates && JSON.parse(storedImageDates).length > 0) {
      setSelectedDate(JSON.parse(storedImageDates)[0]);
    }
  }, []);

  // ScreenShot work start
  const capturePolygonImage = async (containerId) => {
    const container = document.getElementById(containerId);

    if (!container) {
      console.error(`Container with ID ${containerId} not found.`);
      return null;
    }

    // Wait for all map layers to be fully loaded
    await new Promise((resolve) => setTimeout(resolve, 1000));

    try {
      const canvas = await html2canvas(container, {
        useCORS: true, // Enable cross-origin requests
        allowTaint: false, // Do not allow tainted canvases
        logging: true, // Enable logging
        backgroundColor: null, // Capture with a transparent background
      });

      const dataUrl = canvas.toDataURL("image/png");
      // console.log("Captured data URL:", dataUrl); // Log the captured data URL
      return dataUrl;
    } catch (error) {
      console.error("Error during html2canvas capture:", error);
      return null;
    }
  };
  // Screen Shot end

  const handleFetchClick = async () => {
    const { setAoi } = useStore.getState(); // Access the setAoi function from Zustand

    if (startDate && endDate && cropType) {
      // Ensure cropType is also checked
      setLoadingFetch(true); // Set fetching loading to true at the beginning
      await saveCoordinates(aoi); // Save coordinates along with sowing and harvesting dates, and crop type

      try {
        // Capture the screenshot
        const mapImage = await capturePolygonImage("map-container");
        setScreenshotUrl(mapImage);
        // console.log("Screenshot URL:", mapImage);

        // Store the updated AOI in Zustand
        setAoi(aoi);
        // Log the updated aoi state
        console.log("AOI stored in Zustand:", aoi);

        // // Save data to backend
        // await axios.post("http://localhost:8080/cropData/save", {
        //   sowingDate: startDate,
        //   harvestingDate: endDate,
        //   cropType,
        // });

        // Fetch image count and dates
        await fetchImageCountAndDates();
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoadingFetch(false); // Set fetching loading to false at the end or in case of an error
        setIsModalOpen(false);
      }
    } else {
      alert("Please fill in all fields: start date, end date, and crop type.");
    }
  };

  // New handler for updating and fetching images start
  // New handler for updating and fetching images start
  const handleUpdateAndFetchClick = async () => {
    if (startDate && endDate && cropType && selectedPolygon) {
      setLoadingFetch(true); // Show loading indicator

      try {
        // Construct the updated polygon data
        const updatedPolygonData = {
          name: polygonName,
          sowingDate: startDate,
          harvestingDate: endDate,
          cropType: cropType,
        };

        // Update the polygon on the server without changing coordinates
        const token = localStorage.getItem("token"); // Retrieve token from local storage
        const baseUrl =
          process.env.NODE_ENV === "development"
            ? "http://localhost:8080"
            : "https://api.kisan360.net"; // dynamically set protocol (http or https)

        const response = await axios.put(
          `${baseUrl}/api/polygons/${selectedPolygon._id}`,
          updatedPolygonData,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include token in the request headers
            },
          }
        );

        console.log("Polygon updated successfully!");

        // Update the polygons state with the new data
        setPolygons((prevPolygons) =>
          prevPolygons.map((polygon) =>
            polygon._id === response.data._id ? response.data : polygon
          )
        );

        // Update the selected polygon with the new data
        setSelectedPolygon(response.data);

        // Use the existing coordinates in `aoi` for the fetch operation
        const mapImage = await capturePolygonImage("map-container");
        setScreenshotUrl(mapImage);
        console.log("Screenshot URL:", mapImage);

        // Fetch image count and dates using the current polygon's coordinates from the state or database
        await fetchImageCountAndDates();
      } catch (error) {
        console.error("Error during update or image fetching:", error);
      } finally {
        setLoadingFetch(false); // Hide loading indicator
        setIsUpdateModalOpen(false);
      }
    } else {
      alert(
        "Please fill in all fields: name, start date, end date, and crop type."
      );
    }
  };

  // Report generate define start

  const captureAndNavigate = async () => {
    setLoadingGenerateReport(true); // Set loading to true at the beginning

    try {
      // const selectedAreaDetails = predefinedAreas.find(
      //   (area) =>
      //     area.name.en === selectedFarmer || area.name.ur === selectedFarmer
      // );

      // Store the necessary data in local storage
      localStorage.setItem(
        "reportData",
        JSON.stringify({
          screenshotUrl: screenshotUrl, // Use the captured screenshot URL
          // areaDetails: selectedAreaDetails,
          reportDate: selectedDate,
          ndviUrl: getImageUrl("ndvi", selectedDate),
          ndmiUrl: getImageUrl("ndmi", selectedDate),
          ndviGradientUrl: getImageUrl("ndviGradient", selectedDate),
          ndmiGradientUrl: getImageUrl("ndmiGradient", selectedDate),
          viewType,
          // selectedFarmer,
          ndviAreas: ndviAreasForSelectedDate,
          ndmiAreas: ndmiAreasForSelectedDate,
          reciAreas: reciAreasForSelectedDate,
        })
      );

      // Open a new tab and navigate to the report page
      window.open("/reportPage", "_blank");
    } catch (error) {
      console.error("Error generating report:", error);
    } finally {
      setLoadingGenerateReport(false); // Set loading to false at the end or in case of an error
    }
  };

  const handleDateSelect = (event) => {
    setSelectedDate(event.target.value);
    setLoading(true); // Start loading
    // Simulate loading for date selection
    setTimeout(() => setLoading(false), 2500);
  };

  const getImageUrl = (type, date) => {
    const index = availableDates.indexOf(date);
    if (index === -1) return null;

    switch (type) {
      case "ndvi":
        return ndviThumbUrls[index] || null;
      case "ndmi":
        return ndmiThumbUrls[index] || null;
      case "rgb":
        return rgbThumbUrls[index] || null;
      case "ndviGradient":
        return ndviGradientThumbUrls[index] || null;
      case "ndmiGradient":
        return ndmiGradientThumbUrls[index] || null;
      case "reci": // Added RECI case
        return reciThumbUrls[index] || null; // Assuming `reciThumbUrls` is available in the state
      default:
        return null;
    }
  };

  useEffect(() => {
    // Fetch polygons when the component mounts
    const fetchPolygons = async () => {
      try {
        const baseUrl =
          process.env.NODE_ENV === "development"
            ? "http://localhost:8080"
            : "https://api.kisan360.net"; // dynamically set protocol (http or https)
        const token = localStorage.getItem("token"); // Retrieve token from local storage

        const response = await axios.get(`${baseUrl}/api/polygons`, {
          headers: {
            Authorization: `Bearer ${token}`, // Include token in the request headers
          },
        });

        setPolygons(response.data);
        setPolygonsDrawer(response.data);
        console.log("Fetched Polygons:", response.data);
      } catch (error) {
        console.error("There was an error fetching the polygons!", error);
      }
    };

    fetchPolygons();
  }, []);

  const deletePolygon = async (polygonId) => {
    if (!polygonId) {
      console.error("No polygon ID provided for deletion.");
      return;
    }

    try {
      // Determine the base URL based on the environment
      const baseUrl =
        process.env.NODE_ENV === "development"
          ? "http://localhost:8080"
          : "https://api.kisan360.net"; // dynamically set protocol (http or https)
      const token = localStorage.getItem("token"); // Retrieve token from local storage
      console.log("Deleting Polygon ID:", polygonId);

      await axios.delete(`${baseUrl}/api/polygons/${polygonId}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Include token in the request headers
        },
      });

      const updatedPolygons = polygons.filter(
        (polygon) => polygon._id !== polygonId
      );
      setPolygons(updatedPolygons);

      // Remove relevant items from localStorage
      localStorage.removeItem("imageCount");
      localStorage.removeItem("imageDates");
      localStorage.removeItem("ndviThumbUrls");
      localStorage.removeItem("reciThumbUrls");
      localStorage.removeItem("ndviGradientThumbUrls");
      localStorage.removeItem("ndmiGradientThumbUrls");
      localStorage.removeItem("ndmiThumbUrls");
      localStorage.removeItem("trueColorThumbUrls");
      localStorage.removeItem("ndviAreas");
      localStorage.removeItem("ndmiAreas");
      localStorage.removeItem("viewType");
      localStorage.removeItem("aoi");

      // Reset selectedPolygon if it was the deleted one
      if (selectedPolygon && selectedPolygon._id === polygonId) {
        setSelectedPolygon(null);
        setAoi([]);
        setImageCount(0);
        setAvailableDates([]);
        setNdviThumbUrls([]);
        setReciThumbUrls([]);
        setNdviGradientThumbUrls([]);
        setNdmiGradientThumbUrls([]);
        setNdmiThumbUrls([]);
        setRgbThumbUrls([]);
        setNdviAreas([]);
        setNdmiAreas([]);
        setSelectedDate(null);
        setViewType("");
      }

      console.log("Updated Polygons:", updatedPolygons);
      // Refresh the entire screen
      // window.location.reload();
    } catch (error) {
      console.error("There was an error deleting the polygon!", error);
    }
  };

  const saveCoordinates = async (coordinates) => {
    if (!coordinates || coordinates.length === 0) {
      console.error("Invalid coordinates:", coordinates);
      return;
    }
    const finalCropType = cropType === "Other" ? customCropType : cropType;

    try {
      // Determine the base URL based on the environment
      const baseUrl =
        process.env.NODE_ENV === "development"
          ? "http://localhost:8080"
          : "https://api.kisan360.net"; // dynamically set protocol (http or https)
      const token = localStorage.getItem("token"); // Retrieve token from local storage

      const response = await fetch(`${baseUrl}/api/polygons`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include the token in the request headers
        },
        body: JSON.stringify({
          coordinates,
          name: polygonName, // Send the polygon name
          sowingDate: startDate,
          harvestingDate: endDate,
          cropType: finalCropType,
        }),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      console.log("Polygon saved:", data);

      // Clear the form fields (optional)
      // setStartDate(null);
      // setEndDate(null);
      // setCropType("");

      // Fetch updated polygons
      const updatedPolygonsResponse = await fetch(`${baseUrl}/api/polygons`, {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the request headers
        },
      });

      if (!updatedPolygonsResponse.ok) {
        throw new Error(`Error: ${updatedPolygonsResponse.statusText}`);
      }

      const updatedData = await updatedPolygonsResponse.json();
      setPolygons(updatedData);
    } catch (error) {
      console.error("Error saving polygon:", error);
    }
  };

  const handlePolygonSelect = (polygonId) => {
    const selected = polygons.find((polygon) => polygon._id === polygonId);
    setSelectedPolygon(selected);
    setSelectedPolygonId(polygonId); // Track selected polygon ID

    if (
      !selected ||
      !selected.coordinates ||
      selected.coordinates.length === 0
    ) {
      console.error("Selected polygon has no coordinates.");
      return;
    }

    // Set the Area of Interest (AOI) coordinates
    setAoi(selected.coordinates);

    setAoiInZustand(selected.coordinates); // Update Zustand store

    // Retrieve the data for the selected polygon from local storage
    const uniqueId = `polygon_${btoa(JSON.stringify(selected.coordinates))}`;
    const polygonDataString = localStorage.getItem(uniqueId);

    if (polygonDataString) {
      const polygonData = JSON.parse(polygonDataString);

      // Display or use the data as needed
      console.log("Polygon Data:", polygonData);
      setImageCount(polygonData.imageCount);
      setAvailableDates(polygonData.imageDates);
      setNdviThumbUrls(polygonData.ndviThumbUrls);
      setReciThumbUrls(polygonData.reciThumbUrls);
      setNdviGradientThumbUrls(polygonData.ndviGradientThumbUrls);
      setNdmiGradientThumbUrls(polygonData.ndmiGradientThumbUrls);
      setNdmiThumbUrls(polygonData.ndmiThumbUrls);
      setRgbThumbUrls(polygonData.trueColorThumbUrls);
      setNdviAreas(polygonData.ndviAreas);
      setNdmiAreas(polygonData.ndmiAreas);
      setReciAreas(polygonData.reciAreas);

      if (polygonData.imageDates.length > 0) {
        setSelectedDate(polygonData.imageDates[0]);

        // Determine initial view type based on available thumbnail URLs
        if (polygonData.trueColorThumbUrls.length > 0) {
          setViewType("rgb");
          setButtonText(t("Satellite Image"));
        } else if (polygonData.ndviThumbUrls.length > 0) {
          setViewType("ndvi");
          setButtonText(t("Crop Health"));
        } else if (polygonData.ndmiThumbUrls.length > 0) {
          setViewType("ndmi");
          setButtonText(t("Crop Water Content"));
        } else if (polygonData.reciThumbUrls.length > 0) {
          setViewType("reci");
          setButtonText(t("Crop Nitrogen Content"));
        }
      }

      // Display a snackbar message with the field name and image count
      const fieldName = selected.name || "Selected field"; // Default to "Selected field" if no name is present

      enqueueSnackbar(
        `${polygonData.imageCount} Images are available for Field : ${fieldName}`,
        {
          variant: "success",
          autoHideDuration: 4000, // Set snackbar duration to 2 seconds
        }
      );
    } else {
      // No images found, clear the image-related states
      setImageCount(0);
      setAvailableDates([]);
      setNdviThumbUrls([]);
      setReciThumbUrls([]);
      setNdviGradientThumbUrls([]);
      setNdmiGradientThumbUrls([]);
      setNdmiThumbUrls([]);
      setRgbThumbUrls([]);
      setNdviAreas([]);
      setNdmiAreas([]);
      setReciAreas([]);
      setSelectedDate(null);
      setViewType(null);
      // setButtonText(null);
      setButtonText(t("Not Available")); // Set to default text instead of null

      console.error("No data found for the selected polygon.");
      const fieldName = selected.name || "Selected field"; // Default to "Selected field" if no name is present
      enqueueSnackbar(`No images are available for Field : ${fieldName}`, {
        variant: "error",
        autoHideDuration: 4000, // Set snackbar duration to 2 seconds
      });
    }

    if (mapRef.current) {
      const mapInstance = mapRef.current;
      const bounds = selected.coordinates.map((coord) => [
        coord.lat,
        coord.lng,
      ]);

      // Create a LatLngBounds object
      const latLngBounds = L.latLngBounds(bounds);

      // Fit the map to the bounds of the selected polygon
      mapInstance.fitBounds(latLngBounds, { maxZoom: 16 }); // Adjust maxZoom as needed
    }

    console.log("Selected Polygon:", selected);
  };

  useEffect(() => {
    if (selectedPolygon) {
      const stillExists = polygons.some(
        (polygon) => polygon._id === selectedPolygon._id
      );
      if (!stillExists) {
        setSelectedPolygon(null);
        setAoi([]);
      }
    }
  }, [polygons, selectedPolygon]);

  useEffect(() => {
    if (polygons.length === 1) {
      setSelectedPolygon(polygons[0]);
      setAoi(polygons[0].coordinates);
    }
  }, [polygons]);
  const handleLoad = (map) => {
    setMapInstance(map);
  };

  // const handleLocationSelect = ({ lat, lng }) => {
  //   setOriginalCenter({ lat, lng });
  //   setOriginalZoom(15); // Adjust the zoom level as needed
  // };

  // if (loadError) return <div>Error loading maps</div>;
  // if (!isLoaded) return <div>Loading...</div>;
  const isValidCoordinate = (coord) =>
    coord && typeof coord.lat === "number" && typeof coord.lng === "number";

  const validAoi = aoi.filter(isValidCoordinate);

  const bounds = validAoi.length > 0 && {
    north: Math.max(...validAoi.map((coord) => coord.lat)),
    south: Math.min(...validAoi.map((coord) => coord.lat)),
    east: Math.max(...validAoi.map((coord) => coord.lng)),
    west: Math.min(...validAoi.map((coord) => coord.lng)),
  };

  const getAreasForSelectedDate = (areas, availableDates, selectedDate) => {
    const dateIndex = availableDates.indexOf(selectedDate);
    return dateIndex !== -1 ? areas[dateIndex] : null;
  };

  const ndviAreasForSelectedDate = getAreasForSelectedDate(
    ndviAreas,
    availableDates,
    selectedDate
  );

  const ndmiAreasForSelectedDate = getAreasForSelectedDate(
    ndmiAreas,
    availableDates,
    selectedDate
  );
  const reciAreasForSelectedDate = getAreasForSelectedDate(
    reciAreas,
    availableDates,
    selectedDate
  );
  const featureGroupRef = useRef(null);

  const onCreated = (e) => {
    const { layer } = e;
    const polygon = layer.toGeoJSON();
    console.log(polygon);
  };

  //// SideDrawer polygone
  const theme = useTheme(); // Access the theme object
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg")); // Use the theme object with useMediaQuery
  const [open, setOpen] = useState(isLargeScreen);

  useEffect(() => {
    setOpen(isLargeScreen);
  }, [isLargeScreen]);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(null);

  const handleMenuOpen = (event, id) => {
    setAnchorEl(event.currentTarget);
    setDropdownVisible(id);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setDropdownVisible(null);
  };

  const handleDrawerToggle = () => {
    setOpen(!open);
    setDropdownOpen(false);
  };

  const confirmDeletePolygon = (polygonId) => {
    // Close the drawer immediately when the delete action is triggered
    setOpen(false);

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success me-2", // 'me-2' adds a right margin to the confirm button
        cancelButton: "btn btn-danger me-2",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          deletePolygon(polygonId); // Call the delete function
          swalWithBootstrapButtons
            .fire({
              title: "Deleted!",
              text: "Your field has been deleted.",
              icon: "success",
            })
            .then(() => {
              window.location.reload(); // Reload the page after confirming deletion
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire({
            title: "Cancelled",
            text: "Your field is safe :)",
            icon: "error",
          });
        }
      });
  };

  const customDrawOptions = {
    draw: {
      polygon: {
        shapeOptions: {
          color: "goldenrod",
          fillColor: "goldenrod",
          dashArray: "10, 10", // Creates a complex dashed pattern
          fillOpacity: 0, // No fill opacity
        },
        icon: new L.DivIcon({
          className: "custom-draw-icon",
          html: `
            <div style="position: relative; width: 24px; height: 24px;">
              <i class="fa-solid fa-circle" style="color: goldenrod; font-size: 24px;"></i>
              <i class="fa-solid fa-circle" style="color: white; font-size: 8px; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"></i>
            </div>
          `,
        }),
      },
      polyline: false,
      rectangle: false,
      circle: false,
      marker: false,
      circlemarker: false,
    },
    edit: {
      featureGroup: featureGroupRef.current,
    },
  };

  const handleWeatherClick = () => {
    setLoading(true);

    // Simulate loading time (e.g., API call)
    setTimeout(() => {
      navigate("/WeatherForecast"); // Navigate within the same tab      // window.open('/WeatherForecast', '_blank'); // Open in a new tab
    }, 2500); // Adjust time as needed
  };

  return (
    <div>
      {loading && <CircularLoader />}
      <div>
        <ThemeProvider theme={darkModeTheme}>
          <div
            style={{ display: "flex", height: "100vh", overflowX: "hidden" }}
            className="custom-scrollbar"
          >
            <Drawer
              variant="permanent"
              open={open}
              PaperProps={{
                sx: {
                  backgroundColor: "#212930",
                  color: "#9fb4c7",
                  width: open ? 300 : 60, // Increased drawer width
                  transition: "width 0.3s",
                  overflowX: "hidden",
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                },
              }}
            >
              {/* Scrollable Content */}
              <Box
                sx={{
                  flexGrow: 1,
                  overflowY: "auto",
                  overflowX: "hidden",
                  maxHeight: "calc(100vh )",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "8px",
                    justifyContent: open ? "space-between" : "center",
                  }}
                >
                  <IconButton
                    onClick={handleDrawerToggle}
                    sx={{ color: "#9fb4c7" }}
                  >
                    {open ? <ArrowBackIosIcon /> : <MenuIcon />}
                  </IconButton>
                  {open && (
                    <div>
                      <p>
                        <img src={KisanLogo} alt="logo" width={120} />
                      </p>
                    </div>
                  )}
                </Box>
                <Divider sx={{ backgroundColor: "#555" }} />
                {/* Custom Content */}
                <List>
                  {polygons.length > 0 ? (
                    polygons.map((polygon) => {
                      // console.log("asdqadsasd", polygon); // Add this line to log each polygon object

                      return (
                        <ListItem
                          key={polygon._id}
                          sx={{
                            position: "relative", // Ensure the parent element is positioned relative for pseudo-element positioning
                            "&::before": {
                              content: '""',

                              position: "absolute",
                              right: 0,
                              top: "50%",
                              transform: "translateY(-50%)", // Center the border vertically
                              height:
                                selectedPolygonId === polygon._id ? "50%" : "0", // Animate from top to bottom
                              width: "4px", // Width of the left border
                              backgroundColor: "white", // Border color
                              transition: "height 0.3s ease", // Smooth transition for height
                            },
                            "&:hover": {
                              color: "white",
                            },
                            marginBottom: "10px",
                          }}
                        >
                          {!open && (
                            <CustomTooltip
                              title={polygon.name}
                              placement="right"
                            >
                              <ListItemIcon
                                sx={{
                                  color:
                                    selectedPolygonId === polygon._id
                                      ? "white"
                                      : "#9fb4c7", // Slightly lighter background for selected
                                  display: "flex",
                                  alignItems: "center",
                                  cursor: "pointer",
                                }}
                                onClick={() => handlePolygonSelect(polygon._id)}
                                className="py-2"
                              >
                                <ShapeLineIcon />
                              </ListItemIcon>
                            </CustomTooltip>
                          )}
                          {open && (
                            <div
                              style={{
                                width: "100%",
                                padding: "10px",
                                borderRadius: "8px", // More rounded corners for a modern look
                                backgroundColor:
                                  selectedPolygonId === polygon._id
                                    ? "#3a4d63"
                                    : "#323d49", // Slightly lighter background for selected
                                color: "white",
                                height: "150px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",

                                position: "relative",
                                boxShadow:
                                  selectedPolygonId === polygon._id
                                    ? "0 4px 8px rgba(0, 0, 0, 0.3)" // Shadow effect for selected
                                    : "0 1px 3px rgba(0, 0, 0, 0.1)", // Lighter shadow for unselected
                                transform:
                                  selectedPolygonId === polygon._id
                                    ? "scale(1.05)"
                                    : "scale(1)", // Slight zoom effect for selected
                                transition: "all 0.3s ease", // Smooth transition for all effects
                              }}
                              onMouseEnter={(e) => {
                                e.currentTarget.style.backgroundColor =
                                  selectedPolygonId === polygon._id
                                    ? "#40576d"
                                    : "#3b4855"; // Change hover background
                              }}
                              onMouseLeave={(e) => {
                                e.currentTarget.style.backgroundColor =
                                  selectedPolygonId === polygon._id
                                    ? "#3a4d63"
                                    : "#323d49"; // Reset to original background
                              }}
                            >
                              <div style={{ display: "grid", gap: "46px" }}>
                                <div>{renderPolygonThumbnail(polygon)}</div>
                                <div>
                                  <CustomTooltip
                                    title="Find Field"
                                    placement="right"
                                  >
                                    <MyLocationIcon
                                      sx={{
                                        background: "#212930",
                                        borderRadius: "50%",
                                        height: "28px",
                                        cursor: "pointer",
                                        width: "28px",
                                        padding: "4px",
                                        flexShrink: "0",
                                        color:
                                          selectedPolygonId === polygon._id
                                            ? "white"
                                            : "#9fb4c7", // Slightly lighter background for selected                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        handlePolygonSelect(polygon._id)
                                      }
                                    />
                                  </CustomTooltip>
                                </div>
                              </div>

                              <div style={{ display: "grid" }}>
                                <div
                                  style={{
                                    fontSize: "20px",
                                    fontWeight: "500",
                                    color:
                                      selectedPolygonId === polygon._id
                                        ? "white"
                                        : "#9fb4c7", // Slightly lighter background for selected
                                  }}
                                >
                                  {polygon.name || `Field ${polygon._id}`}
                                </div>
                                <div
                                  style={{
                                    fontSize: "10px",
                                    fontWeight: "400",
                                    color:
                                      selectedPolygonId === polygon._id
                                        ? "white"
                                        : "#9fb4c7", // Slightly lighter background for selected
                                  }}
                                >
                                  {polygon.cropType
                                    ? polygon.cropType
                                    : "Unknown Crop Type"}
                                </div>
                              </div>
                              <div style={{ display: "grid", gap: "85px" }}>
                                <CustomTooltip
                                  title="Get Data"
                                  placement="right"
                                >
                                  <div key={polygon.id}>
                                    <ImageSearchIcon
                                      sx={{
                                        background: "#212930",
                                        borderRadius: "50%",
                                        height: "28px",
                                        cursor: "pointer",
                                        width: "28px",
                                        padding: "4px",
                                        flexShrink: "0",
                                        color:
                                          selectedPolygonId === polygon._id
                                            ? "white"
                                            : "#9fb4c7", // Slightly lighter background for selected                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        handleMenuClose();
                                        handleOpenUpdateModal(polygon); // Pass the polygon data here
                                        handlePolygonSelect(polygon._id);
                                      }}
                                    />
                                  </div>
                                </CustomTooltip>
                                <CustomTooltip
                                  title="Delete Field"
                                  placement="right"
                                >
                                  <DeleteForeverIcon
                                    sx={{
                                      background: "#212930",
                                      borderRadius: "50%",
                                      height: "28px",
                                      width: "28px",
                                      padding: "4px",
                                      flexShrink: "0",
                                      cursor: "pointer",
                                      color:
                                        selectedPolygonId === polygon._id
                                          ? "white"
                                          : "#9fb4c7", // Slightly lighter background for selected                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      handleMenuClose();
                                      confirmDeletePolygon(polygon._id);
                                    }}
                                  />
                                </CustomTooltip>
                              </div>
                            </div>
                          )}
                        </ListItem>
                      );
                    })
                  ) : (
                    <ListItem>
                      {open ? (
                        <div
                          className={`add-field-parent ${
                            isUrdu ? "rtl" : "ltr"
                          }`}
                          dir={isUrdu ? "rtl" : "ltr"}
                        >
                          <div className="add-field-child">
                            <div
                              className={`draw-icon ${
                                isUrdu ? "rtl-icon" : ""
                              }`}
                            >
                              <DrawIcon
                                sx={{ fontSize: "30px", color: "#9fb4c7" }}
                              />
                            </div>
                            <div className="draw-text-parent">
                              <div
                                className="first-text-draw"
                                style={{
                                  color: "#9fb4c7",
                                  fontFamily: isUrdu
                                    ? "Noto Nastaliq Urdu"
                                    : "Arial, sans-serif",
                                }}
                              >
                                {t("Add your fields")}
                              </div>
                              <div
                                className="second-text-draw"
                                style={{ color: "#9fb4c7" }}
                              >
                                {t(
                                  "Browse satellite images, vegetation indices, weather data, and much more"
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            textAlign: "center",
                            width: "100%",
                            marginTop: "13rem",
                          }}
                        >
                          <DrawIcon
                            sx={{ fontSize: "30px", color: "#9fb4c7" }}
                          />
                          <div style={{ color: "#9fb4c7", fontSize: "12px" }}>
                            {t("Draw your field")}
                          </div>
                        </div>
                      )}
                    </ListItem>
                  )}
                </List>
              </Box>

              {/* Fixed Bottom Section */}
              <Box sx={{ position: "relative" }}>
                <Divider sx={{ backgroundColor: "#555" }} />
                <Box
                  sx={{
                    padding: open ? "10px" : "5px",
                    display: "flex",
                    flexDirection: open ? "row" : "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: open ? "20px" : "5px",
                    // marginRight: "30px",
                    ...(isRtl
                      ? { marginRight: "0px" }
                      : { marginRight: "30px" }),

                    bottom: 0,
                    // left: 10,
                    width: open ? 260 : 60, // Match drawer width
                    backgroundColor: "#212930", // Match drawer background
                  }}
                >
                  <div>
                    <LogOutDropDown />
                  </div>
                  <div
                    style={{
                      marginRight: open ? (isRtl ? "0" : "30px") : "0",
                      marginLeft: open ? (isRtl ? "20px" : "0") : "0",
                    }}
                  >
                    <LanguageSwitcher />
                  </div>
                  <div>
                    <Feedback />
                  </div>
                </Box>
              </Box>
            </Drawer>

            {/* Map content start  */}
            <div style={{ width: "100%" }}>
              <MapContainer
                center={[31.3656, 72.9942]}
                zoom={13}
                id="map-container"
                style={{ height: "100vh" }}
                ref={mapRef}
                whenCreated={handleLoad}
              >
                <TileLayer
                  url="https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoic2FxaWJ1dG0iLCJhIjoiY2x6dGZobzIwMjh0NDJrcXJpanV1d3RkdiJ9._Oco5XwyrD4x14TlQ_HdNQ"
                  attribution='&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a>'
                />

                <div style={{ position: "relative", zIndex: "700" }}>
                  <CustomZoomControl />
                </div>
                {selectedDate && viewType && bounds && (
                  <ImageOverlay
                    key={`${viewType}-${selectedDate}-${new Date().getTime()}`}
                    url={getImageUrl(viewType, selectedDate)}
                    bounds={[
                      [bounds.south, bounds.west],
                      [bounds.north, bounds.east],
                    ]} // Use Leaflet bounds format
                  />
                )}
                <FeatureGroup ref={featureGroupRef}>
                  <EditControl
                    position="topright"
                    onCreated={handlePolygonCreated}
                    draw={customDrawOptions.draw}
                    edit={customDrawOptions.edit}
                  />
                  {aoi.length > 0 && (
                    <Polygon
                      positions={aoi.map((coord) => [coord.lat, coord.lng])}
                      pathOptions={{
                        color: "white", // Border color
                        fillColor: "goldenrod", // Fill color (if fillOpacity is 0, this should not be visible)
                        fillOpacity: 0, // No fill opacity
                        opacity: 1, // Fully opaque border
                        dashArray: "10, 10", // Dotted line pattern
                      }}
                    />
                  )}
                  {/* <EditControl
                    position="topright"
                    onCreated={onCreated}
                    draw={customDrawOptions.draw}
                    edit={customDrawOptions.edit}
                  /> */}
                </FeatureGroup>

                {/* <div style={{ position: "relative", zIndex: "700" }}>
                  <CurrentLocation />
                </div> */}

                {/* Ndvi or Ndmi Map type view change on map  */}
                <div
                  style={{
                    position: "absolute",
                    // top: 10,
                    // right: 10,
                    zIndex: 700,
                  }}
                >
                  {viewType === "ndvi" && (
                    <LegendNdvi
                      ndviAreas={ndviAreasForSelectedDate}
                      ndmiAreas={ndmiAreasForSelectedDate}
                    />
                  )}
                  {viewType === "ndmi" && (
                    <LegendNdmi
                      ndviAreas={ndviAreasForSelectedDate}
                      ndmiAreas={ndmiAreasForSelectedDate}
                    />
                  )}
                  {viewType === "rgb" && (
                    <LegendRgb
                      ndviAreas={ndviAreasForSelectedDate}
                      ndmiAreas={ndmiAreasForSelectedDate}
                    />
                  )}
                  {viewType === "reci" && (
                    <LegendReci reciAreas={reciAreasForSelectedDate} />
                  )}
                </div>
                {/* Ndvi or Ndmi Map type view change on map end  */}

                {/* Select Map Type Start */}

                <div>
                  {!isRtl && (
                    <div
                      className="map-type dropup"
                      style={{
                        zIndex: "700",
                        position: "absolute",
                        left: "11%",
                        bottom: "100px",
                      }}
                    >
                      <CustomTooltip title={t("Weather")} placement="left">
                        <Button
                          variant="contained"
                          sx={{
                            padding: "5px",
                            background: "#1e1e1e",
                            width: "5px",
                            color: "#f4f4f4",
                            // left: "8rem", // For LTR
                          }}
                          onClick={handleWeatherClick}
                        >
                          <CloudIcon />
                        </Button>
                      </CustomTooltip>

                      <a
                        className="btn btn-dark dropdown-toggle"
                        id="darkDropupMenuButton"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        disabled={
                          !ndviThumbUrls.length && !ndmiThumbUrls.length
                        }
                        style={{
                          backgroundColor: "#1e1e1e",
                          color: "white",
                          display: "flex",
                          alignItems: "center",
                          justifyContent:"space-between",
                          width:"190px",
                          cursor:
                            !ndviThumbUrls.length && !ndmiThumbUrls.length
                              ? "no-drop"
                              : "pointer",
                        }}
                      >
                        {buttonText}
                        {rgbThumbUrls.length > 0 && (
                          <div className="red-dot"></div> // Conditional red dot
                        )}
                      </a>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuLink"
                        style={{ backgroundColor: "#2c2c2c", color: "white" }}
                      >
                        <li>
                          <a
                            className={`dropdown-item dropdown-legend-item ${
                              viewType === "rgb" ? "active" : ""
                            }`}
                            onClick={() => handleViewTypeSelect("rgb")}
                            style={{
                              color: "white",
                              backgroundColor:
                                viewType === "rgb" ? "#3a4d63" : "transparent", // Highlight
                            }}
                          >
                            {t("Satellite Image")}
                          </a>
                        </li>
                        <li>
                          <a
                            className={`dropdown-item dropdown-legend-item ${
                              viewType === "ndvi" ? "active" : ""
                            }`}
                            onClick={() => handleViewTypeSelect("ndvi")}
                            style={{
                              color: "white",
                              backgroundColor:
                                viewType === "ndvi" ? "#3a4d63" : "transparent", // Highlight
                            }}
                          >
                            {t("Crop Health")}
                          </a>
                        </li>
                        <li>
                          <a
                            className={`dropdown-item dropdown-legend-item ${
                              viewType === "ndmi" ? "active" : ""
                            }`}
                            onClick={() => handleViewTypeSelect("ndmi")}
                            style={{
                              color: "white",
                              backgroundColor:
                                viewType === "ndmi" ? "#3a4d63" : "transparent", // Highlight
                            }}
                          >
                            {t("Crop Water Content")}
                          </a>
                        </li>
                        <li>
                          <a
                            className={`dropdown-item dropdown-legend-item ${
                              viewType === "reci" ? "active" : ""
                            }`}
                            onClick={() => handleViewTypeSelect("reci")}
                            style={{
                              color: "white",
                              backgroundColor:
                                viewType === "reci" ? "#3a4d63" : "transparent", // Highlight
                            }}
                          >
                            {t("Crop Nitrogen Content")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}

                  {isRtl && (
                    <div
                      className="map-type-2 dropup"
                      style={{
                        position: "relative",
                        zIndex: "700",
                        position: "absolute",
                        right: "14%",
                        bottom: "100px",
                      }}
                    >
                      <a
                        className="btn btn-dark dropdown-toggle"
                        id="darkDropupMenuButton"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        disabled={
                          !ndviThumbUrls.length && !ndmiThumbUrls.length
                        }
                        style={{
                          backgroundColor: "#1e1e1e",
                          color: "white",
                          display: "flex",
                          marginLeft: "-50px",
                          alignItems: "center",
                          width:"178px",
                          justifyContent:"space-between",
                          gap: "0px",
                          cursor:
                            !ndviThumbUrls.length && !ndmiThumbUrls.length
                              ? "no-drop"
                              : "pointer",
                        }}
                      >
                        {buttonText}
                        {rgbThumbUrls.length > 0 && (
                          <div className="red-dot"></div> // Conditional red dot
                        )}
                      </a>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuLink"
                        style={{ backgroundColor: "#2c2c2c", color: "white" }}
                      >
                        <li>
                          <a
                            className={`dropdown-item dropdown-legend-item ${
                              viewType === "rgb" ? "active" : ""
                            }`}
                            onClick={() => handleViewTypeSelect("rgb")}
                            style={{
                              color: "white",
                              backgroundColor:
                                viewType === "rgb" ? "#3a4d63" : "transparent", // Highlight
                            }}
                          >
                            {t("Satellite Image")}
                          </a>
                        </li>
                        <li>
                          <a
                            className={`dropdown-item dropdown-legend-item ${
                              viewType === "ndvi" ? "active" : ""
                            }`}
                            onClick={() => handleViewTypeSelect("ndvi")}
                            style={{
                              color: "white",
                              backgroundColor:
                                viewType === "ndvi" ? "#3a4d63" : "transparent", // Highlight
                            }}
                          >
                            {t("Crop Health")}
                          </a>
                        </li>
                        <li>
                          <a
                            className={`dropdown-item dropdown-legend-item ${
                              viewType === "ndmi" ? "active" : ""
                            }`}
                            onClick={() => handleViewTypeSelect("ndmi")}
                            style={{
                              color: "white",
                              backgroundColor:
                                viewType === "ndmi" ? "#3a4d63" : "transparent", // Highlight
                            }}
                          >
                            {t("Crop Water Content")}
                          </a>
                        </li>
                        <li>
                          <a
                            className={`dropdown-item dropdown-legend-item ${
                              viewType === "reci" ? "active" : ""
                            }`}
                            onClick={() => handleViewTypeSelect("reci")}
                            style={{
                              color: "white",
                              backgroundColor:
                                viewType === "reci" ? "#3a4d63" : "transparent", // Highlight
                            }}
                          >
                            {t("Crop Nitrogen Content")}
                          </a>
                        </li>
                      </ul>
                      <CustomTooltip title={t("Weather")} placement="left">
                        <Button
                          variant="contained"
                          sx={{
                            padding: "5px",
                            background: "#1e1e1e",
                            width: "5px",
                            color: "#f4f4f4",
                            // right: "34%", // For RTL
                          }}
                          onClick={handleWeatherClick}
                        >
                          <CloudIcon />
                        </Button>
                      </CustomTooltip>
                    </div>
                  )}
                </div>

                {/* Select Map Type End */}

                {/* Date selection slider on map start */}
                <div className="date-slider-wrapper">
                  {availableDates.length === 0 ? (
                    // Conditionally render the loader or the "Add Field" text
                    loadingFetch ? (
                      <CircularProgress size={22} sx={{ color: "white" }} />
                    ) : (
                      <div className="empty-slider-message">
                        {t("Field Images")}
                      </div>
                    )
                  ) : (
                    <>
                      <button
                        className="slider-arrow left"
                        onClick={scrollPrev}
                      >
                        <FontAwesomeIcon icon={faChevronLeft} />
                      </button>
                      <div className="date-slider">
                        <div className="dates-container">
                          {availableDates.map((date) => (
                            <div
                              key={date}
                              className={`date-item ${
                                date === selectedDate ? "active" : ""
                              }`}
                              onClick={() => handleDateClick(date)}
                            >
                              <div className="date-text">{date}</div>
                              {/* <div className="date-subtext">S2</div> */}
                            </div>
                          ))}
                        </div>
                      </div>
                      <button
                        className="slider-arrow right"
                        onClick={scrollNext}
                      >
                        <FontAwesomeIcon icon={faChevronRight} />
                      </button>
                    </>
                  )}
                </div>
                {/* Date selection slider on map end */}

                {/* <Link
                  to="/WeatherForecast"
                  className="weather-button-parent-md"
                  style={{ textDecoration: "none" }}
                >
                  <CustomTooltip title={t("Weather")} placement="right">
                    <Button
                      variant="contained"
                      sx={{
                        position: "relative",
                        zIndex: "700",
                        padding: "5px",
                        background: "#fefefe",
                        width: "5px",
                        color: "#343a40",
                        ...(isRtl ? { right: "10%" } : { left: "13rem" }),
                        // left: "",
                        // bottom: "2.3rem",
                      }}
                    >
                      <WbSunnyIcon sx={{ fontSize: "20px" }} />
                    </Button>
                  </CustomTooltip>
                </Link> */}
                <div
                  style={{
                    position: "relative",
                    zIndex: "700",
                    marginTop: "40px",
                  }}
                >
                  <SearchComponent />
                </div>
                <div
                  className="container mt-4"
                  style={{
                    position: "relative",
                    zIndex: "700",
                    ...(isRtl ? { right: "10%" } : { left: "10%" }),
                  }}
                ></div>

                {/* <div>
                  <button onClick={handleOpenModal}>Open Modal</button>
                </div> */}

                <div style={{ position: "relative", zIndex: "700" }}>
                  {showWeatherWidget && (
                    <WeatherWidgets coordinates={selectedCoords} />
                  )}
                </div>

                {/* Modal for when polygon is created */}
                <div>
                  {/* <button onClick={handleOpenModal}>Open Modal</button> */}

                  <Dialog
                    open={isModalOpen}
                    onClose={handleCloseModal}
                    fullWidth
                    maxWidth="sm"
                    aria-labelledby="form-dialog-title"
                    PaperProps={{
                      style: {
                        backgroundColor: "#000000", // Dark background for the modal
                        color: "#fff", // White text color
                      },
                    }}
                  >
                    <DialogTitle
                      id="form-dialog-title"
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "24px",
                        position: "relative",
                        color: "#fff", // White text color
                      }}
                    >
                      {t("Add Crop Details")}
                      <IconButton
                        aria-label="close"
                        onClick={handleCloseModal}
                        style={{
                          position: "absolute",
                          right: 8,
                          top: 8,
                          color: "#fff", // White color for the close button
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent>
                      <div className="d-flex flex-column gap-1">
                        {/* Field Name */}
                        <div className="mt-2 input-group-sm">
                          <label
                            htmlFor="polygonNameInput"
                            className="form-label"
                          >
                            <span
                              style={{
                                fontWeight: "bolder",
                                fontSize: "19px",
                                color: "#fff",
                              }}
                            >
                              {t("Field Name")}
                            </span>
                            {/* <span style={{ color: "red" }}>*</span> */}
                          </label>
                          <input
                            type="text"
                            className="form-control mt-2"
                            id="polygonNameInput"
                            value={polygonName}
                            onChange={(e) => setPolygonName(e.target.value)}
                            placeholder={t("Enter field name")}
                            style={{ backgroundColor: "#555", color: "#fff" }} // Dark input background and white text
                          />
                        </div>
                      </div>

                      <div className="d-flex flex-column gap-1">
                        <div className="mt-2 input-group-sm">
                          <label
                            htmlFor="cropTypeSelect"
                            className="form-label"
                          >
                            <span
                              style={{
                                fontWeight: "bolder",
                                fontSize: "19px",
                                color: "#fff",
                              }}
                            >
                              {t("Crop Type")}
                            </span>
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="form-select"
                            id="cropTypeSelect"
                            value={cropType}
                            onChange={handleCropTypeChange}
                            style={{ backgroundColor: "#555", color: "#fff" }} // Dark select background and white text
                          >
                            <option value="" disabled>
                              {t("Select a crop")}
                            </option>

                            <option value="Wheat"> {t("Wheat")} </option>
                            <option value="Maize"> {t("Maize")} </option>
                            <option value="Rice"> {t("Rice")} </option>
                            <option value="Cotton"> {t("Cotton")} </option>
                            <option value="Sugarcane">
                              {" "}
                              {t("Sugarcane")}{" "}
                            </option>
                            <option value="Fodder"> {t("Fodder")} </option>
                            <option value="Other"> {t("Other")} </option>
                          </select>
                          {cropType === "Other" && (
                            <input
                              type="text"
                              className="form-control mt-2"
                              id="cropTypeInput"
                              value={customCropType}
                              onChange={(e) =>
                                setCustomCropType(e.target.value)
                              }
                              placeholder="Enter your crop"
                              style={{ backgroundColor: "#555", color: "#fff" }} // Dark input background and white text
                            />
                          )}
                        </div>

                        <label
                          style={{
                            fontWeight: "bolder",
                            fontSize: "19px",
                            color: "#fff",
                          }}
                        >
                          {t("Sowing Date:")}
                        </label>
                        <DatePicker
                          className="w-100 form-select form-select-sm"
                          selected={startDate}
                          onChange={handleStartDateChange}
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                          maxDate={new Date()} // Maximum possible start date is today
                          placeholderText="yyyy-MM-dd"
                          dateFormat="yyyy-MM-dd"
                          style={{ backgroundColor: "#555", color: "#fff" }}
                        />

                        <label
                          style={{
                            fontWeight: "bolder",
                            fontSize: "19px",
                            color: "#fff",
                          }}
                        >
                          {t("Harvesting Date:")}
                        </label>
                        <DatePicker
                          className="w-100 form-select form-select-sm"
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate || new Date()}
                          maxDate={calculateMaxEndDate(startDate)}
                          placeholderText="yyyy-MM-dd"
                          dateFormat="yyyy-MM-dd"
                          style={{ backgroundColor: "#555", color: "#fff" }}
                          disabled={!startDate} // Disable until a start date is selected
                        />
                      </div>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: "center" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleFetchClick}
                        disabled={loadingFetch}
                        style={{
                          width: "150px",
                          backgroundColor: "#004ba1",
                          color: "white",
                        }} // Darker blue for the button
                      >
                        {loadingFetch ? (
                          <CircularProgress size={16} sx={{ color: "white" }} />
                        ) : (
                          t("Fetch Images")
                        )}
                      </Button>
                    </DialogActions>
                    <DialogContent
                      style={{ textAlign: "center", color: "#fff" }}
                    >
                      <p className="mt-2">
                        <span style={{ fontWeight: "bold", fontSize: "19px" }}>
                          {t("Available images")}:
                        </span>
                        <span
                          style={{
                            color: "red",
                            fontWeight: "bold",
                            margin: "3px",
                          }}
                        >
                          {imageCount ? imageCount : "0"}
                        </span>
                      </p>
                    </DialogContent>
                  </Dialog>
                </div>

                {/* Update Modal for when polygon is created */}
                <div>
                  <Dialog
                    open={isUpdateModalOpen}
                    onClose={handleCloseModal}
                    fullWidth
                    maxWidth="sm"
                    aria-labelledby="form-dialog-title"
                    PaperProps={{
                      style: {
                        backgroundColor: "#000000", // Dark background for the modal
                        color: "#fff", // White text color
                      },
                    }}
                  >
                    <DialogTitle
                      id="form-dialog-title"
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "24px",
                        position: "relative",
                        color: "#fff", // White text color
                      }}
                    >
                      {t("Update Crop Details")}
                      <IconButton
                        aria-label="close"
                        onClick={handleCloseModal}
                        style={{
                          position: "absolute",
                          right: 8,
                          top: 8,
                          color: "#fff", // White color for the close button
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent>
                      <div className="d-flex flex-column gap-1">
                        {/* Field Name */}
                        <div className="mt-2 input-group-sm">
                          <label
                            htmlFor="polygonNameInput"
                            className="form-label"
                          >
                            <span
                              style={{
                                fontWeight: "bolder",
                                fontSize: "19px",
                                color: "#fff",
                              }}
                            >
                              {t("Field Name")}
                            </span>
                            {/* <span style={{ color: "red" }}>*</span> */}
                          </label>
                          <input
                            type="text"
                            className="form-control mt-2"
                            id="polygonNameInput"
                            value={polygonName}
                            onChange={(e) => setPolygonName(e.target.value)}
                            placeholder={t("Enter field name")}
                            style={{ backgroundColor: "#555", color: "#fff" }} // Dark input background and white text
                          />
                        </div>
                      </div>

                      <div className="d-flex flex-column gap-1">
                        <div className="mt-2 input-group-sm">
                          <label
                            htmlFor="cropTypeSelect"
                            className="form-label"
                          >
                            <span
                              style={{
                                fontWeight: "bolder",
                                fontSize: "19px",
                                color: "#fff",
                              }}
                            >
                              {t("Crop Type")}
                            </span>
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="form-select"
                            id="cropTypeSelect"
                            value={cropType}
                            onChange={handleCropTypeChange}
                            style={{ backgroundColor: "#555", color: "#fff" }} // Dark select background and white text
                          >
                            <option value="" disabled>
                              {t("Select a crop")}
                            </option>

                            <option value="Wheat"> {t("Wheat")} </option>
                            <option value="Maize"> {t("Maize")} </option>
                            <option value="Rice"> {t("Rice")} </option>
                            <option value="Cotton"> {t("Cotton")} </option>
                            <option value="Sugarcane">
                              {" "}
                              {t("Sugarcane")}{" "}
                            </option>
                            <option value="Fodder"> {t("Fodder")} </option>
                            <option value="Other"> {t("Other")} </option>
                          </select>
                          {cropType === "Other" && (
                            <input
                              type="text"
                              className="form-control mt-2"
                              id="cropTypeInput"
                              value={customCropType}
                              onChange={(e) =>
                                setCustomCropType(e.target.value)
                              }
                              placeholder="Enter your crop"
                              style={{ backgroundColor: "#555", color: "#fff" }} // Dark input background and white text
                            />
                          )}
                        </div>

                        <label
                          style={{
                            fontWeight: "bolder",
                            fontSize: "19px",
                            color: "#fff",
                          }}
                        >
                          {t("Sowing Date:")}
                        </label>
                        <DatePicker
                          className="w-100 form-select form-select-sm"
                          selected={startDate}
                          onChange={handleStartDateChange}
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                          maxDate={new Date()} // Maximum possible start date is today
                          placeholderText="yyyy-MM-dd"
                          dateFormat="yyyy-MM-dd"
                          style={{ backgroundColor: "#555", color: "#fff" }}
                        />

                        <label
                          style={{
                            fontWeight: "bolder",
                            fontSize: "19px",
                            color: "#fff",
                          }}
                        >
                          {t("Harvesting Date:")}
                        </label>
                        <DatePicker
                          className="w-100 form-select form-select-sm"
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate || new Date()}
                          maxDate={calculateMaxEndDate(startDate)}
                          placeholderText="yyyy-MM-dd"
                          dateFormat="yyyy-MM-dd"
                          style={{ backgroundColor: "#555", color: "#fff" }}
                          disabled={!startDate} // Disable until a start date is selected
                        />
                      </div>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: "center" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleUpdateAndFetchClick}
                        disabled={loadingFetch}
                        style={{
                          width: "240px",
                          backgroundColor: "#004ba1",
                          color: "white",
                        }}
                      >
                        {loadingFetch ? (
                          <>
                            <CircularProgress
                              size={16}
                              sx={{ color: "white", marginRight: "8px" }}
                            />
                            Fetching Images...
                          </>
                        ) : (
                          "Update and Fetch Images"
                        )}
                      </Button>
                    </DialogActions>
                    <DialogContent
                      style={{ textAlign: "center", color: "#fff" }}
                    >
                      <p className="mt-2">
                        <span style={{ fontWeight: "bold", fontSize: "19px" }}>
                          {t("Available images")}:
                        </span>
                        <span
                          style={{
                            color: "red",
                            fontWeight: "bold",
                            margin: "3px",
                          }}
                        >
                          {imageCount ? imageCount : "0"}
                        </span>
                      </p>
                    </DialogContent>
                  </Dialog>
                </div>
              </MapContainer>
            </div>
            {/* Map content end  */}
          </div>
        </ThemeProvider>
      </div>

      {/* weather button and generate report button end */}
    </div>
  );
};

export default EarthEngineData;

// working on next tasks get polygone against user ID
